import React, { useMemo, useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import ListDropdown from "../../components/lists/dropdown";
import CardLink from "../../components/cards/card-link";
import { GatsbyImage } from "gatsby-plugin-image";
import CustomButtonText from "../../components/button/custom-button";
import ListOfCards from "../../components/cards/list-of-cards";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getAlternateLang } from "../../utils/alternate-lang";
import NoResult from "../../components/lists/noResult";
import Guidelines from "../../components/misc/guidelines";
import BrickNewsletter from "../../components/bricks/newsletter";
import { gtmPushUserInfo } from "../../services/auth";

export const query = graphql`
  fragment StrapiNewsletterEditorialContentFragment on StrapiNewsletterEditorialContent {
    Newsletter {
      title
      subtitle
      background {
        alternativeText
      }
    }
    backgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query EditorialContents($language: String!) {
    editorialContentsEn: allStrapiEditorialContent(
      filter: {
        editorial_content_type: {
          Slug: { in: ["article", "disease-awareness"] }
        }
        locale: { eq: "en" }
        # date: { ne: null }
      }
      sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          id
          strapiId
          localizations {
            id
          }
          Title
          Slug
          Description
          date(formatString: "MMMM DD, YYYY")
          therapeutical_area {
            Name
            Slug
            color
          }
          Thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 223)
              }
            }
          }
          editorial_content_type {
            Name
            Slug
            color
          }
        }
      }
    }
    editorialContentsEs: allStrapiEditorialContent(
      filter: {
        editorial_content_type: {
          Slug: { in: ["article", "disease-awareness"] }
        }
        locale: { eq: "es-PA" }
        # date: { ne: null }
      }
      sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          id
          strapiId
          localizations {
            id
          }
          Title
          Slug
          Description
          date(formatString: "MMMM DD, YYYY")
          therapeutical_area {
            Name
            Slug
            color
          }
          Thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 223)
              }
            }
          }
          editorial_content_type {
            Name
            Slug
            color
          }
        }
      }
    }
    therapeuticalAreas: allStrapiTherapeuticalArea {
      edges {
        node {
          Name
          Slug
          color
        }
      }
    }
    strapiNewsletterEditorialContentEn: strapiNewsletterEditorialContent(
      locale: { eq: "en" }
    ) {
      ...StrapiNewsletterEditorialContentFragment
    }
    strapiNewsletterEditorialContentEs: strapiNewsletterEditorialContent(
      locale: { eq: "es-PA" }
    ) {
      ...StrapiNewsletterEditorialContentFragment
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const getCardFromEditorialContent = (editorialContent, t, isFiltered) => {
  if (editorialContent.node.editorial_content_type.Slug === "article") {
    return getCardFromArticle(editorialContent, t);
  }
  return getCardFromDiseaseAwareness(editorialContent, isFiltered);
};

const getCardFromArticle = (article, t) => {
  return (
    <CardLink
      title={article.node.Title}
      type="article"
      image={
        <GatsbyImage
          image={
            article.node.Thumbnail.localFile.childImageSharp.gatsbyImageData
          }
          alt={
            article.node.Thumbnail.alternativeText
              ? article.node.Thumbnail.alternativeText
              : ""
          }
        />
      }
      date={article.node.date}
      labelText={t("article")}
      labelColor={article.node.editorial_content_type.color}
      link={`/medical-informations/${article.node.Slug}`}
      therapeuticAreaLabel={article.node.therapeutical_area.Name}
      therapeuticAreaColor={article.node.therapeutical_area.color}
    />
  );
};

const getCardFromDiseaseAwareness = (diseaseAwareness, isFiltered) => {
  return (
    <CardLink
      title={diseaseAwareness.node.Title}
      type="disease-awarness"
      image={
        <GatsbyImage
          image={
            diseaseAwareness.node.Thumbnail.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={
            diseaseAwareness.node.Thumbnail.alternativeText
              ? diseaseAwareness.node.Thumbnail.alternativeText
              : ""
          }
        />
      }
      date=""
      labelText=""
      labelColor=""
      link={`/medical-informations/${diseaseAwareness.node.Slug}`}
      borderColor={
        isFiltered ? "" : diseaseAwareness.node.therapeutical_area.color
      }
      therapeuticAreaLabel={diseaseAwareness.node.therapeutical_area.Name}
      therapeuticAreaColor={diseaseAwareness.node.therapeutical_area.color}
      centeredText={true}
    />
  );
};

const ListEditorialContents = ({ data }) => {
  const { language, t } = useI18next();
  const editorialContents =
    language === "en"
      ? data.editorialContentsEn.edges
      : data.editorialContentsEs.edges;
  const newsletter =
    language === "en"
      ? data.strapiNewsletterEditorialContentEn
      : data.strapiNewsletterEditorialContentEs;
  const [activeSlugs, setActiveSlugs] = useState([]);

  const [seeHowMany, setSeeHowMany] = useState(3);

  const isFiltered = useMemo(() => {
    return activeSlugs.length > 0;
  }, [activeSlugs]);

  const filteredEditorialContents = useMemo(() => {
    return activeSlugs.length > 0
      ? editorialContents.filter((ec) =>
          activeSlugs.includes(ec.node.therapeutical_area.Name)
        )
      : [];
  }, [activeSlugs, editorialContents]);

  const seo = {
    metaTitle: t("metaData.medInf.metaTitle"),
    metaDescription: t("metaData.medInf.metaDescription"),
    linkAlternateHref:
      "/" + getAlternateLang(language) + "/medical-informations",
    linkHrefLang: getAlternateLang(language),
  };

  const onChangeTherapeuticalAreas = (values) => {
    setSeeHowMany(values.length > 0 ? 6 : 3);
    setActiveSlugs(values);
  };

  const onLoadMore = () => {
    setSeeHowMany((previousValue) => previousValue + 3);
  };

  const articles = editorialContents.filter(
    (editorialContent) =>
      editorialContent.node.editorial_content_type.Slug === "article"
  );

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Medical_Informations",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  return (
    <Layout seo={seo}>
      <ListDropdown onChange={onChangeTherapeuticalAreas} />
      {isFiltered ? (
        filteredEditorialContents.length > 0 ? (
          <ListOfCards
            title={t("latestArticles")}
            cards={filteredEditorialContents
              .slice(0, seeHowMany)
              .map((editorialContent) =>
                getCardFromEditorialContent(editorialContent, t, isFiltered)
              )}
            button={
              filteredEditorialContents.length > seeHowMany ? (
                <CustomButtonText
                  text="Load More"
                  onClick={onLoadMore}
                  style={{
                    letterSpacing: "0.1em",
                  }}
                />
              ) : (
                <></>
              )
            }
            backgroundColor="#FFFFFF"
            disableCarousel={true}
            isTitleH1={true}
          />
        ) : (
          <NoResult />
        )
      ) : (
        <>
          <ListOfCards
            title={t("latestArticles")}
            cards={articles
              .map((article) => getCardFromArticle(article, t, language))
              .slice(0, seeHowMany)}
            button={
              articles.length > seeHowMany ? (
                <CustomButtonText
                  text="Load More"
                  onClick={onLoadMore}
                  style={{
                    letterSpacing: "0.1em",
                  }}
                />
              ) : (
                <></>
              )
            }
            backgroundColor="#FFFFFF"
            style={{ marginTop: 20, paddingLeft: 0, paddingRight: 0 }}
            isTitleH1={true}
          />
        </>
      )}
      <Guidelines activeSlugs={activeSlugs} />
      {newsletter && (
        <BrickNewsletter
          title={newsletter.Newsletter.title}
          subtitle={newsletter.Newsletter.subtitle}
          buttonText={newsletter.Newsletter.buttonText}
          backgroundImageData={
            newsletter.backgroundImage[0].localFile.childImageSharp
              .gatsbyImageData
          }
          alternativeText={newsletter.Newsletter.background.alternativeText}
          style={{ padding: 0 }}
        />
      )}
    </Layout>
  );
};
export default ListEditorialContents;
