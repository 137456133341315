import { useTheme } from "@emotion/react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useMemo, useState } from "react";
import ListOfCards from "../../components/cards/list-of-cards";
import CustomButtonText from "../button/custom-button";
import CardLink from "../cards/card-link";
import NoResult from "../lists/noResult";

const Guidelines = ({ activeSlugs }) => {
  const theme = useTheme();
  const data = useStaticQuery(graphql`
    query Guidelines {
      guidelinesEn: allStrapiEditorialContent(
        filter: {
          editorial_content_type: { Slug: { eq: "guideline" } }
          locale: { eq: "en" }
          # date: { ne: null }
        }
        sort: { order: DESC, fields: [date] }
      ) {
        edges {
          node {
            id
            strapiId
            localizations {
              id
            }
            Title
            Slug
            Description
            date(formatString: "MMMM DD, YYYY")
            therapeutical_area {
              Name
              Slug
              color
            }
            Thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 276, height: 223)
                }
              }
            }
            editorial_content_type {
              Name
              Slug
              color
            }
          }
        }
      }
      guidelinesEs: allStrapiEditorialContent(
        filter: {
          editorial_content_type: { Slug: { eq: "guideline" } }
          locale: { eq: "es-PA" }
          # date: { ne: null }
        }
        sort: { order: DESC, fields: [date] }
      ) {
        edges {
          node {
            id
            strapiId
            localizations {
              id
            }
            Title
            Slug
            Description
            date(formatString: "MMMM DD, YYYY")
            therapeutical_area {
              Name
              Slug
              color
            }
            Thumbnail {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 276, height: 223)
                }
              }
            }
            editorial_content_type {
              Name
              Slug
              color
            }
          }
        }
      }
      therapeuticalAreas: allStrapiTherapeuticalArea {
        edges {
          node {
            Name
            Slug
            color
          }
        }
      }
    }
  `);

  const { t, language } = useI18next();
  const [seeHowMany, setSeeHowMany] = useState(3);

  const onLoadMore = () => {
    setSeeHowMany((previousValue) => previousValue + 3);
  };
  const getCardFromGuideline = (guideline, t) => {
    return (
      <CardLink
        title={guideline.node.Title}
        type="guideline"
        image={
          <GatsbyImage
            image={
              guideline.node.Thumbnail.localFile.childImageSharp.gatsbyImageData
            }
            alt={guideline.node.Thumbnail.alternativeText}
          />
        }
        date={guideline.node.date}
        labelText={t("guideline")}
        labelColor={guideline.node.editorial_content_type.color}
        link={`/medical-informations/${guideline.node.Slug}`}
        therapeuticAreaLabel={guideline.node.therapeutical_area.Name}
        therapeuticAreaColor={guideline.node.therapeutical_area.color}
      />
    );
  };
  const guidelines =
    language === "en" ? data.guidelinesEn.edges : data.guidelinesEs.edges;

  const filteredGuidelines = useMemo(() => {
    return activeSlugs.length > 0
      ? guidelines.filter((ec) =>
          activeSlugs.includes(ec.node.therapeutical_area.Name)
        )
      : guidelines;
  }, [activeSlugs, guidelines]);

  const isFiltered = useMemo(() => {
    return activeSlugs.length > 0;
  }, [activeSlugs]);

  return filteredGuidelines.length > 0 ? (
    <ListOfCards
      title={t("latestGuidelines")}
      cards={filteredGuidelines
        .slice(0, seeHowMany)
        .map((editorialContent) =>
          getCardFromGuideline(editorialContent, t, isFiltered)
        )}
      button={
        filteredGuidelines.length > seeHowMany ? (
          <CustomButtonText
            text="Load More"
            onClick={onLoadMore}
            style={{
              letterSpacing: "0.1em",
            }}
          />
        ) : (
          <></>
        )
      }
      backgroundColor={theme.palette.servier.lightBlue + "4D"}
      disableCarousel={false}
    />
  ) : (
    <></>
  );
};

export default Guidelines;
